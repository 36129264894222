<script>
import AuthService from '@/services/AuthService'

export default {
  name: 'Logout',

  mounted() {
    const auth = new AuthService()
    auth.logout().then(
      () => {
        this.$store.commit('auth/logout')

        localStorage.removeItem('isImpersonatingUser')

        this.$posthog.reset()

        this.$router.push({ name: 'auth-login' })
      },
      error => {
        console.log(error)
      }
    )
  },
}
</script>

<template></template>
